import React from 'react';
import {Button, Form, Input, message, Select, Spin} from 'antd';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';
import AntdAddressSetForm from 'rev.sdk.js/Components/AntdAddressSetForm';
import * as AppActions from '../../AppActions';
import {MEMBERSHIP, MEMBERSHIP_DISPLAY} from '../../Domain/Constants';

function ProfilePage(props) {
  const [user, setUser] = useOutlet('user');
  const [form] = Form.useForm();
  const [values, setValues] = React.useState({});

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      AppActions.setLoading(true);
      const _profile = await AppActions.updateProfile({
        name: values.name,
        phone: values.phone,
        zip: values.zip,
        city: values.city,
        district: values.district,
        address: values.address,
        email: values.email,
      });

      setUser({...user, data: {..._profile}});
      message.success('更新個人資訊成功！');
    } catch (ex) {
      message.error(`更新個人資訊失敗，請稍侯再嘗試。`);
    } finally {
      AppActions.setLoading(false);
    }
  };

  const shouldShowWarnText = React.useMemo(() => {
    if (!user.data || !user.data.email || !user.data.name || !user.data.phone) {
      return true;
    }
    return false;
  }, [user.data]);

  if (!user.data) {
    return <Spin />;
  }

  return (
    <Wrapper>
      <div className="container">
        <h2>個人資訊</h2>
        {shouldShowWarnText && (
          <div className="warn-text">
            請填寫您的姓名、手機、可收信電子信箱，以利加速購買流程喔！
          </div>
        )}
        <Form
          style={{marginTop: 25}}
          name="control-hooks"
          form={form}
          initialValues={{
            ...user.data,
          }}
          onValuesChange={(changedValues, allValues) => {
            setValues({...allValues, ...changedValues});
          }}
          onFinish={onFinish}>
          <Form.Item
            label="姓名"
            name="name"
            rules={[{required: true, message: '請輸入您的姓名'}]}>
            <Input />
          </Form.Item>
          <Form.Item label="會員等級" name="membership">
            <Select disabled>
              <Select.Option value={MEMBERSHIP.normal}>
                {MEMBERSHIP_DISPLAY.normal}
              </Select.Option>
              <Select.Option value={MEMBERSHIP.vip}>
                {MEMBERSHIP_DISPLAY.vip}
              </Select.Option>
              <Select.Option value={MEMBERSHIP.vvip}>
                {MEMBERSHIP_DISPLAY.vvip}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="手機"
            name="phone"
            rules={[
              {
                required: true,
                pattern: /^[0-9]{10}$/,
                message: '請輸入您的手機號碼，共10碼',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="電子信箱"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: '請輸入您的電子信箱',
              },
            ]}>
            <Input disabled={!!user.data.email} />
          </Form.Item>
          <AntdAddressSetForm form={form} />
          <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button
              htmlType="button"
              style={{marginRight: 20}}
              onClick={onReset}>
              重設
            </Button>
            <Button htmlType="submit" type="primary">
              儲存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    & .warn-text {
      color: #ff4848;
      margin-top: 10px;
    }
  }
`;

export default ProfilePage;
